import { User, WindowAccess } from "../models/user/User";
import { UserSettings } from "../models/user/UserSettings";
import { get, set, remove } from "./Preferences";
import { getUserSessionSettings } from "../api/UserSetups";
import { getWindowsAccessForPlant } from "../api/SecurityWindowAccess";

const _userSettingsKey = "UserSettings";
const _userKey = "UserInfo";

export const getUserId = async () => {
  const user = await getUser();
  if (user !== null) {
    return user.Id;
  }
  return null;
};

export const getUserDisplayName = async () => {
  const user = await getUser();
  if (user !== null) {
    return user.DisplayName;
  }
  return null;
};

export const getUserDatabaseId = async () => {
  return await get(_userSettingsKey).then((userSettings: UserSettings) => {
    return userSettings.SessionSettingList.Database;
  });
};

export const getUserPlantId = async () => {
  return await get(_userSettingsKey).then((userSettings: UserSettings) => {
    return userSettings.SessionSettingList.Plant;
  });
};

export const getUserTechnicianAccess = async () => {
  return getUserHasAccess("mob_technician", "Update");
};

export const getUserRequesterAccess = async () => {
  return getUserHasAccess("mob_requester", "Update");
};

export const getStoresAccess = async () => {
  return getUserHasAccess("mob_stores", "Update");
};

export const getEmployeesAccess = async () => {
  return getUserHasAccess(
    "w_wo_worker_time_eu",
    "Has access to other employee ids"
  );
};

export const getCraftCodeAccess = async () => {
  return getUserHasAccess(
    "w_wo_worker_time_eu",
    "Has access to all craft codes"
  );
};

export const getRemarksAccess = async () => {
  return getUserHasAccess("mob_remarks", "Remarks");
};

export const getTimeEntryAccess = async () => {
  return getUserHasAccess("mob_worker_time_reporting", "New");
};

export const getMeterReadingAccess = async () => {
  return getUserHasAccess("mob_eq_meter_readings", "New");
};

export const getInspectionAccess = async () => {
  return getUserHasAccess("mob_inspections", "Update");
};

export const getCCAAccess = async () => {
  return getUserHasAccess("mob_cca_codes", "CCA");
};

export const getIssueFromTechAccess = async () => {
  return getUserHasAccess("mob_in_issues", "Issue Material");
};

export const getReturnFromTechAccess = async () => {
  return getUserHasAccess("mob_in_return", "Return Material");
};

export const getIssueAccess = async () => {
  return getUserHasAccess("mob_stores_issue", "Issue Material");
};

export const getReturnAccess = async () => {
  return getUserHasAccess("mob_stores_return", "Return Material");
};

export const getCycleCountAccess = async () => {
  return getUserHasAccess("mob_stores_cycle_count", "Cycle Count");
};

export const getBinAccess = async () => {
  return getUserHasAccess("mob_stores_bintobin", "Bin to Bin Transfer");
};

export const getItemAdjustAccess = async () => {
  return getUserHasAccess("mob_stores_itemadjust", "Item Adjustment");
};

export const getApprovalAccess = async () => {
  return getUserHasAccess("mob_wo_approvals", "Update");
};

export const getMobReqApprovalAccess = async () => {
  return getUserHasAccess("mob_req_approvals", "Update");
};

export const getUserHasAccess = async (windowName: string, action: string) => {
  return get(_userKey).then((userInfo: User) => {
    return (
      userInfo?.UserAccess?.find((ua) => {
        return ua.WindowName === windowName && ua.Action === action;
      })?.HasAccess ?? false
    );
  });
};
export const getUserSettings = async () => {
  return (await get(_userSettingsKey)) as UserSettings;
};

export const setUserSettings = async (userSettings: UserSettings) => {
  await remove(_userSettingsKey);
  await set(_userSettingsKey, userSettings);
};

export const getUser = async () => {
  return (await get(_userKey)) as User;
};

export const setUser = async (user: User) => {
  await remove(_userKey);
  await set(_userKey, user);
};

export const loadUserSettings = async () => {
  const response = await getUserSessionSettings();
  if (response.status === 200) {
    await createUserSettings(response.data);
    return true;
  }
  console.log("GetUserSessionSettings error: ", response.data);
  return false;
};

const createUserSettings = async (responseData: any) => {
  const userSettings: UserSettings = {
    DocumentProviders: [],
    SessionSettingList: {
      Database: responseData.SessionSettingsList.Database,
      EmployeeEmailAddress:
        responseData.SessionSettingsList.EmployeeEmailAddress,
      MaxsizeForDocumentUpload:
        responseData.SessionSettingsList.MaxsizeForDocumentUpload,
      Plant: responseData.SessionSettingsList.Plant,
      UserDisplayName: responseData.SessionSettingsList.UserDisplayName,
      UserId: responseData.SessionSettingsList.UserId,
      CloudSuiteManaged: responseData.SessionSettingsList.CloudSuiteManaged
    },
    UserOptionsList: {
      SessionTimeout: responseData.UserOptionsList.SessionTimeout,
      EnableItemAdjFromIssue:
        responseData.UserOptionsList.EnableItemAdjFromIssue,
    },
    ModulesInstalled: {
      MultipleWarehousing: responseData.ModulesInstalled.MultipleWarehousing,
    },
  };
  //update doc providers
  responseData.DocProvidersList.forEach((docProvider: any) => {
    userSettings.DocumentProviders.push({
      ApplicationId: docProvider.ApplicationId,
      Authority: docProvider.Authority,
      Enabled: docProvider.Enabled,
      Name: docProvider.Name,
      Plant: docProvider.Plant,
      Providerid: docProvider.Providerid,
      Secret: docProvider.Secret,
      Type: docProvider.Type,
      Url: docProvider.Url,
    });
  });
  //store user settings
  await setUserSettings(userSettings);
  //create user object and store
  await createUserInfo(userSettings);
};

const createUserInfo = async (userSettings: UserSettings) => {
  const userId = userSettings.SessionSettingList?.UserId;
  const plant = userSettings.SessionSettingList?.Plant;
  let ua: WindowAccess[] = [];

  if (!!userId && !!plant) {
    const res = await getWindowsAccessForPlant(userId, plant);
    if (!res.isError && res.data) {
      ua = res.data;
    }
  }
  const user: User = {
    Id: userId!,
    DisplayName: userSettings.SessionSettingList?.UserDisplayName!,
    EmployeeEmailAddress:
      userSettings.SessionSettingList?.EmployeeEmailAddress!,
    UserAccess: ua,
  };
  //store user
  await setUser(user);
};
