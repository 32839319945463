import {
  IonButton,
  IonIcon,
  useIonAlert,
  useIonRouter,
  useIonViewDidLeave,
} from "@ionic/react";
import { alarmOutline, alarmSharp } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { decodeParam } from "../util/ApiHelper";
import { get, set } from "../util/Preferences";
import TimeEntry from "../pages/TimeEntry";
import {
  TranslationMessagesContext,
  TranslationsContext,
} from "../util/Translations";

export let finalTime = 0;
let timerSet = false;

interface StartStopProps {
  timeAccess?: boolean;
}

const StartStopComponent: React.FC<StartStopProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const { translatedMessages } = useContext(TranslationMessagesContext);
  const { timeAccess } = props;
  const { workOrderId } = useParams<{ workOrderId: string }>();
  let displayWo = decodeParam(workOrderId);
  let [timer, setTimer] = useState<any>();
  let [hours, setHours] = useState<number>();
  const [presentAlert] = useIonAlert();
  const router = useIonRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation(); // Get the current location
  const [workOrder, setWorkOrder] = useState<string>();
  const timeEntryFunctionCalled = useRef(false); // Ref to track if metadataQuery has been called
  const storedTimer = localStorage.getItem("StartTime");

  interface WorkOrderTimer {
    workOrderNumber: string;
    startTime: number;
  }

  const onModalCancel = () => {
    setIsModalOpen(false);
  };

  const onModalConfirm = () => {
    setIsModalOpen(false);
  };

  const getWorkOrder = async () => {
    set("WorkOrder", displayWo);
  };

  const startTimer = () => {
    console.log("Timer Started");
    getWorkOrder();
    let startingTime: WorkOrderTimer = {
      workOrderNumber: displayWo,
      startTime: Date.now(),
    };
    set("StartTime", startingTime);
    localStorage.setItem("StartTime", JSON.stringify(startingTime)); // Store in local storage
    const date = new Date(startingTime.startTime);
    console.log(date.toLocaleDateString());
    setTimer(startingTime);
  };

  const stopTimer = () => {
    presentAlert({
      header:
        translatedMessages["SubmitTime"]?.MessageText ||
        "Do you want to submit your time?",
      buttons: [
        {
          text: translations["lbl_btn_no"] || "No",
          role: "cancel",
        },
        {
          text: translations["lbl_btn_yes"] || "Yes",
          role: "confirm",
          handler: () => {
            stopTimerhandler();
          },
        },
      ],
    });
  };


  const checkOngoingTimer = () => {
    if (timeEntryFunctionCalled.current) return;
    timeEntryFunctionCalled.current = true;

    if (storedTimer) {
      const parsedTimer = JSON.parse(storedTimer),
        currentTime = Date.now(),
        elapsedTime = currentTime - parsedTimer.startTime;
      if (elapsedTime > 0) {
        const startTimeDate = new Date(parsedTimer.startTime),
          // Format the Date object to display hours, minutes, and seconds
          hours = startTimeDate.getHours().toString().padStart(2, '0'),
          minutes = startTimeDate.getMinutes().toString().padStart(2, '0'),
          seconds = startTimeDate.getSeconds().toString().padStart(2, '0'),
          formattedTime = `${hours}:${minutes}:${seconds}`;

        // Prompt the user
        presentAlert({
          header: translatedMessages["OngoingTimer"]?.MessageText || "Ongoing Timer",
          message: `You have an ongoing timer started at ${formattedTime} for the work order ${parsedTimer.workOrderNumber}. Do you want to continue or stop it?`,
          buttons: [
            {
              text: translations["lbl_btn_continue"] || "Continue",
              role: "cancel",
              handler: () => {
                // Continue the timer
                setTimer(parsedTimer);
              },
            },
            {
              text: translations["lbl_btn_stop"] || "Stop",
              role: "confirm",
              handler: () => {
                // Stop the timer
                stopTimerhandler()
              },
            },
          ],
        });
      }
    }
  };

  const stopTimerhandler = async () => {
    timerSet = false;
    let parsedTimer = null;
    if (storedTimer) {
      parsedTimer = JSON.parse(storedTimer);
    }
    let oldTime = await timer;
    if (oldTime === undefined) {
      oldTime = parsedTimer;
    }
    let newTime: WorkOrderTimer = {
      workOrderNumber: oldTime.workOrderNumber,
      startTime: Date.now(),
    };
    set("StartTime", newTime);
    let endTime = newTime.startTime,
      // ellapsed time in seconds
      timeInMS = Math.abs(endTime - oldTime.startTime);

    console.log("Final time (seconds): ", timeInMS / 1000);
    // seconds to minutes
    let timeInMin = Math.floor(timeInMS / 1000 / 60);
    // minutes to hours, rounded up to next quarter hour
    finalTime = ((Math.ceil(timeInMin / 15) * 15) % 60) / 60;
    if (finalTime === 0) {
      finalTime += 0.25;
    }
    setHours(finalTime);
    // Reset state of timer
    set("StartTime", "");
    setTimer(undefined);
    localStorage.removeItem("StartTime");
    // Navigate to time entry page
    if (oldTime.workOrderNumber !== displayWo) {
      router.push(`/workorder/${oldTime.workOrderNumber}`);
    }
    console.log("Work Order: ", displayWo);
    setIsModalOpen(true);
  };

  const setStartorStop = () => {
    if (timer) {
      stopTimer();
    } else {
      startTimer();
    }
  };

  useEffect(() => {
    let parsedTimer = null;
    if (storedTimer) {
      parsedTimer = JSON.parse(storedTimer);
    }
    if (timerSet === true && !storedTimer) {
      setTimer(get("StartTime"));
    }
    else if (storedTimer && !location.pathname.includes("workorder")) {
      checkOngoingTimer();
      setWorkOrder(parsedTimer.workOrderNumber);
    }
    else if (storedTimer && parsedTimer.workOrderNumber === displayWo) {
      setTimer(get("StartTime"));
    }
  }, []);

  useIonViewDidLeave(() => {
    if (timer) {
      timerSet = true;
    }
  }, [timer]);

  return (
    <>
      <IonButton
        fill="clear"
        slot="end"
        disabled={!timeAccess}
        onClick={() => setStartorStop()}
      >
        <IonIcon icon={timer ? alarmSharp : alarmOutline}></IonIcon>
      </IonButton>
      {finalTime > 0 && (
        <TimeEntry
          isOpen={isModalOpen}
          cancel={onModalCancel}
          confirm={onModalConfirm}
          finalTime={hours}
          workOrder={workOrder}
        />
      )}
    </>
  );
};

export default StartStopComponent;
