import { FieldsBase } from "../common/FieldsBase";

export interface WorkOrderMetadata {
  Groups?: any[];
  fields?: any;
  Actions?: any[];
  IsAutoPO?: boolean;
}

export interface WorkOrderGroup {
  TextId?: string;
  Width?: number;
  GroupOrder: number;
  Icon?: any;
  GroupName?: string;
  ParentGroup?: string;
  Groups?: WorkOrderGroup[];
  IsRepeatable?: boolean;
  Inflatable?: boolean;
}

export interface WorkOrderField extends FieldsBase {}

export interface WorkOrderActivity {
  Activity: string;
  ActivityDate: string;
  ActivityTime: string;
  ActivityUser: string;
  Comments?: string;
  PlannedReviewer: string;
}

export interface WorkOrder {
  SerialDescription?: any;
  EquipmentDescription?: any;
  CfWorkOrder?: string;
  CfMasterPlan?: string;
  CfStandardRequest?: string;
  WoNumber?: string;
  WoStatusDescription?: any;
  WoApprovalFlag?: boolean;
  NotebookTranslations?: any;
  SourceTableName?: string;
  RowVersion?: any;
  Description?: string;
  Status?: any;
  Priority?: string;
  Supervisor?: any;
  Planner?: any;
  Area?: string;
  Department?: any;
  RequestedCompletion?: string;
  Equipment?: string;
  WoType?: string;
  Criticality?: string;
  WorkClass?: string;
  Shop?: any;
  Project?: any;
  Account?: string;
  JobPlanPrint?: any;
  PicklistPrint?: any;
  SrBase?: any;
  SrTask?: string;
  SrSubtask?: string;
  MpBase?: any;
  MpTask?: string;
  MpSubtask?: string;
  EnteredBy?: string;
  EnteredDate?: string;
  CompletedDate?: any;
  Requestor?: string;
  Approver?: any;
  DateApproved?: string;
  NextReviewer?: any;
  UpDownFlag?: boolean;
  DownHours?: number;
  SafetyFlag?: boolean;
  ShutdownCode?: any;
  EstimatedCost?: number;
  PlannedLabor?: number;
  PlannedNonstock?: number;
  PlannedStores?: number;
  PlannedServices?: number;
  PlannedTools?: number;
  PlannedHours?: number;
  MechanicalIntegrity?: any;
  Iso9000?: any;
  EquipmentClass?: string;
  AccountingValid?: boolean;
  TemplatePlanning?: boolean;
  OnRequestedComplFlag?: boolean;
  RequestorEmailAddress?: any;
  AssignedTo?: string;
  DeviceWorkOrderId?: any;
  Technician1?: any;
  Technician2?: any;
  Technician3?: any;
  Technician4?: any;
  Technician5?: any;
  Technician6?: any;
  ScheduledDate?: string;
  ExternalWorkOrder?: any;
  LastModified?: string;
  MobileDeviceStatus?: string;
  PrintAsRouteFlag?: boolean;
  WriteWoToSerialFlag?: boolean;
  SerialId?: any;
  District?: string;
  Alarm?: any;
  SourceApplication?: any;
  InspectionId?: number;
  MpInspectionType?: any;
  Comment?: {
    SourceTableName?: string;
    RowVersion?: any;
    LongDescription?: string;
    ObjectId?: string;
    Plant?: string;
    WoBase?: string;
    WoTask?: string;
    WoSubtask?: string;
  };
  Instruction?: any;
  Permits?: any;
  Resources?: any;
  Events?: any;
  EquipmentMaster?: any;
  Labor?: any;
  Activity?: any;
  ScheduledCrafts?: any;
  ScheduledAssignments?: any;
  InspectionDefinition?: any;
  CostPosting?: any;
  WorkOrderPrintOverride?: any;
  Extract?: any;
  SkipTheStatusCheck?: boolean;
  ObjectId?: string;
  Plant?: string;
  WoBase?: string;
  WoTask?: string;
  WoSubtask?: string;
}

export interface Requisition {
  Desc: any;
  ExtCost: number;
  ItemDesc: string;
  LineDetail: string;
  LineNum: number;
  MessageList: any[];
  Qty: number;
  ReqNum: string;
  Success: boolean;
  UOM: string;
  UnitCost: number;
  lstMobileApprovalRequisitionLineComments: WorkOrderActivity[];
  checked: boolean;
}

const getFieldsFromMetadata = (metadata: WorkOrderMetadata) => {
  const fields: WorkOrderField[] = [];
  const metadataFields = metadata.fields;
  // Get list of keys from metadata to iterate through
  const keys = Object.keys(metadataFields);
  keys.forEach((key) => {
    const field = metadataFields[key];
    fields.push(field);
  });
  return fields;
};

export const getWorkOrderGroups = (workOrder?: WorkOrderMetadata) => {
  if (!workOrder) {
    return [];
  }
  const groups = (workOrder.Groups as WorkOrderGroup[]) || [];
  const sortedGroups = [...groups].sort((a, b) => {
    return a.GroupOrder - b.GroupOrder;
  });
  //Append a group for fields not associated with any group.
  sortedGroups.push({
    GroupName: undefined,
    GroupOrder: sortedGroups.length + 1,
  });
  return sortedGroups;
};

export const getFieldsByGroup = (
  metadata: WorkOrderMetadata,
  groupName?: string
) => {
  const fields = getFieldsFromMetadata(metadata).filter((field) => {
    if (!groupName) {
      return !field.GroupName;
    }
    return field.GroupName === groupName;
  });
  return [...fields].sort((a, b) => {
    return +a.FieldOrder - +b.FieldOrder;
  });
};

export const getAllFields = (metadata: WorkOrderMetadata) => {
  const fields = getFieldsFromMetadata(metadata);
  return [...fields].sort((a, b) => {
    return +a.FieldOrder - +b.FieldOrder;
  });
};

export const formatData = (data: any, field: WorkOrderField) => {
  if(data === 0 && field.PropertyName === "Status"){
    return data;
  }
  if (!data) {
    return "";
  }
  if (field.ValidValues) {
    const validValue = field.ValidValues.find((value) => {
      return value.Code === String(data);
    });
    return !!validValue ? `${data} - ${validValue?.Description}` : data;
  }
  if (field.NetType === "DateTime") {
    const date = new Date(data);
    return date.toLocaleDateString();
  }
  return data;
};
