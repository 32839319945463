import {
  IonList,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonButton,
  IonIcon,
  useIonViewWillEnter,
} from "@ionic/react";
import { searchOutline, searchSharp } from "ionicons/icons";
import CriteriaModal from "./CriteriaModal";
import {
  CriteriaDef,
  CriteriaParameter,
  FilterParam,
} from "../hooks/useQueryExec";
import { useContext, useEffect, useState } from "react";
import { TranslationsContext } from "../util/Translations";
import { getUserSetupValues } from "../api/UserSetups";
import FilterModal from "./FilterModal";
import { ApiResponse } from "../models/api/ApiResponse";
import { getValueSearchDetails } from "../api/QueryDesigner";
import { getUserId } from "../util/UserHelper";

export interface QueryFilterParams {
  setShowLoading: (arg0: boolean) => void;
  getQueryList: () => void;
  queryList: string[];
  querySection: string;
  getFilterParams: () => void;
  filterParams: FilterParam[];
  setParameter: (
    paramKey: string,
    paramValue: string | number | null | undefined
  ) => void;
  queryCriteria: CriteriaDef[];
  getQueryCriteria: (queryName: string) => void;
  searchCallback: (
    selectedQuery: string,
    filterParams?: FilterParam[],
    criteriaParams?: CriteriaParameter[]
  ) => void;
  filterModalInvalid?: boolean;
}

const QueryFilter: React.FC<QueryFilterParams> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const {
    setShowLoading,
    getQueryList,
    queryList,
    querySection,
    getFilterParams,
    filterParams,
    setParameter,
    queryCriteria,
    getQueryCriteria,
    searchCallback,
    filterModalInvalid,
  } = props;
  let [loggedInEmployee, setLoggedInEmployee] = useState<string>("");
  const [currentQuery, setCurrentQuery] = useState<string>("");
  const [isCriteriaModalOpen, setIsCriteriaModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterModalColumnName, setFilterModalColumnName] = useState("");
  const [filterModalModuleName, setFilterModalModuleName] = useState("");
  const startup = "startup query";
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  useEffect(() => {
    setShowLoading(true);
    Promise.all([getEmployee(), getFilterParams(), getQueryList()]).finally(
      () => {
        getDefaultQuery();
        setIsInitialLoadComplete(true);
      }
    );
  }, []);

  useEffect(() => {
    if (currentQuery) {
      getQueryCriteria(currentQuery);
    }
  }, [isInitialLoadComplete, currentQuery]);

  useIonViewWillEnter(() => {
    getDefaultQuery();
  });

  const getEmployee = async () => {
    return await getUserId().then((response) => {
      if (response !== undefined) {
        setLoggedInEmployee(response!);
      }
    });
  };

  const getDefaultQuery = async () => {
    const data = {
      Section: querySection,
      ParameterList: [startup],
    };
    getUserSetupValues(data).then((response) => {
      if (response.data && response.data[startup]?.includes("--")) {
        let query = response.data[startup].split("--");
        setCurrentQuery(query[1]);
      } else {
        setCurrentQuery(response.data[startup]);
      }
    });
    setShowLoading(false);
  };

  const onSearch = () => {
    if (queryCriteria.length > 0) {
      setIsCriteriaModalOpen(true);
    } else {
      setShowLoading(true);
      searchCallback(currentQuery, filterParams);
    }
  };

  const onCriteriaModalCancel = () => {
    setIsCriteriaModalOpen(false);
  };

  const onCriteriaModalConfirm = (parameters: CriteriaParameter[]) => {
    setIsCriteriaModalOpen(false);
    searchCallback(currentQuery, filterParams, parameters);
  };

  const onFilterModalCancel = () => {
    setIsFilterModalOpen(false);
    setFilterModalColumnName("");
    setFilterModalModuleName("");
  };

  const onFilterModalConfirm = (selectedItem: string) => {
    setParameter(filterModalColumnName, selectedItem);
    setIsFilterModalOpen(false);
    setFilterModalColumnName("");
    setFilterModalModuleName("");
  };

  return (
    <>
      <CriteriaModal
        isOpen={isCriteriaModalOpen}
        cancel={onCriteriaModalCancel}
        confirm={onCriteriaModalConfirm}
        queryCriteria={queryCriteria}
        loggedInEmployee={loggedInEmployee}
      />
      {filterModalInvalid ? (
        <></>
      ) : (
        <FilterModal
          isOpen={isFilterModalOpen}
          moduleName={filterModalModuleName}
          cancel={onFilterModalCancel}
          confirm={onFilterModalConfirm}
        />
      )}
      <IonList>
        <IonItem>
          <IonLabel position="floating">{`${
            translations["lbl_grp_query"] || "Query"
          }:`}</IonLabel>
          <IonSelect
            id="query-filter-select"
            interface="popover"
            placeholder={translations["lbl_btn_select"] || "Select"}
            value={currentQuery}
            onIonChange={(ev) => setCurrentQuery(ev.detail.value)}
          >
            {queryList.map((qryName, index) => {
              return (
                <IonSelectOption key={`${index}-${qryName}`}>
                  {qryName}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
        {filterParams.map((p) => {
          return (
            <IonItem key={p.ColumnName}>
              <IonLabel>{p.TranslatedIdText}</IonLabel>
              <IonInput
                class="ion-text-end"
                value={p.ParameterValue}
                onIonInput={(ev) => setParameter(p.ColumnName, ev.target.value)}
                maxlength={p.MaxSize}
              ></IonInput>
              {p.Requirements.length > 0 &&
                !!p.Requirements.find(
                  (value) => value.RequirementType === "Searchable"
                ) && (
                  <IonIcon
                    slot="end"
                    ios={searchOutline}
                    md={searchSharp}
                    onClick={async () => {
                      setFilterModalColumnName(p.ColumnName);
                      const response: ApiResponse = await getValueSearchDetails(
                        {
                          tableName: p.TableName,
                          columnName: p.ColumnName,
                        }
                      );
                      setFilterModalModuleName(
                        response.data.QueryWizardModuleName
                      );
                      setIsFilterModalOpen(true);
                    }}
                  ></IonIcon>
                )}
            </IonItem>
          );
        })}
        <IonButton
          id="query-filter-search-button"
          disabled={!currentQuery}
          expand="full"
          color="primary"
          onClick={() => {
            onSearch();
          }}
        >
          {translations["lbl_btn_search"] || "Search"}
        </IonButton>
      </IonList>
    </>
  );
};

export default QueryFilter;
