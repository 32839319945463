import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { alertCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import { initializeAbpSession } from "../api/abpLogin";
import { clearApiSession } from "../util/ApiOptionsHelper";

interface ApiErrorProps {
    errorData: any;
    showRelogin?: boolean;
    showCancel?: boolean;
    showOk?: boolean;
    setShowLoading?: (loading: boolean) => void;
};

const ApiError: React.FC<ApiErrorProps> = (props) => {
    const { errorData, showRelogin, showCancel, showOk, setShowLoading } = props;
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        setErrorText(errorData);
    }, [errorData]);

    const onReLogin = async () => {
        setErrorText("");
        if (setShowLoading) setShowLoading(true);
        await clearApiSession();
        window.location.href = "/AuthCallback";
    };

    const onOk = async () => {
        setErrorText("");
        if (setShowLoading) setShowLoading(true);
        await initializeAbpSession(true);
        window.location.href = "/Home";
    }

    const onCancel = async () => {
        setErrorText("");
        if (setShowLoading) setShowLoading(true);
        await clearApiSession();
        window.location.href = "/AuthCallback";
    }


    return (!!errorText ? (
        <>
            <IonItem color="danger">
                <IonIcon icon={alertCircle}></IonIcon>
                <IonLabel class="ion-text-wrap">{errorText?.toString()}</IonLabel>
                <IonButton onClick={onOk} hidden={!showOk}>OK</IonButton>
                <IonButton onClick={onCancel} hidden={!showCancel}>Cancel</IonButton>
                <IonButton onClick={onReLogin} hidden={!showRelogin}>Retry Login</IonButton>
            </IonItem>
        </>
    ) : (
        <></>
    ));
};

export default ApiError;