import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
} from "@ionic/react";
import PageHeader from "../components/PageHeader";
import { useParams } from "react-router";
import { decodeParam, encodeParam } from "../util/ApiHelper";
import { useContext, useEffect, useState } from "react";
import {
  Equipment,
  EquipmentHierarchyChildType,
  EquipmentHierarchyType,
} from "../models/equipment/Equipment";
import { getEquipmentHierarchyList } from "../api/EqupimentHierarchies";
import { getEquipment } from "../api/EquipmentMasters";
import { TranslationsContext } from "../util/Translations";
import "./EquipmentHierarchy.css";

const EquipmentHierarchy: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const { equipmentId } = useParams<{ equipmentId: string }>();
  const displayEquipment = decodeParam(equipmentId);
  const [hierarchy, setHierarchy] = useState<EquipmentHierarchyType>();
  const [showLoading, setShowLoading] = useState(false);
  const [equipment, setEquipment] = useState<Equipment>();
  const [elements, setElements] = useState<JSX.Element>();
  const [eqpList, setEqpList] = useState<EquipmentHierarchyChildType[]>([]);

  useEffect(() => {
    setHierarchy(undefined);
    setEquipment(undefined);
    setElements(undefined);
    setup();
  }, [displayEquipment]);

  useEffect(() => {
    if (hierarchy && equipment) {
      const elements = mapHierarchy(
        displayEquipment.trim(),
        (equipment?.Description || "").trim(),
        displayEquipment,
        1 //starting levelNumber
      );
      setElements(elements);
    }
  }, [hierarchy, equipment]);

  const getHierarchy = async (part: string) => {
    const params = {
      equipment: part,
    };
    const response = await getEquipmentHierarchyList(params);
    return response.data as EquipmentHierarchyType;
  };
  const getEquipmentDetails = async (name: string) => {
    const response = await getEquipment({ equipment: name });
    return response.data as Equipment;
  };

  const setup = async () => {
    setShowLoading(true);
    const [hierarchyDetails, equipment] = await Promise.all([
      getHierarchy(displayEquipment),
      getEquipmentDetails(displayEquipment),
    ]);
    setHierarchy(hierarchyDetails);
    setEquipment(equipment);
    setEqpList(hierarchyDetails.eqpList || []);
    setShowLoading(false);
  };

  //Maps equipmemnt list to accordions based on hierarchy. If the partType is 1, allow navigation and work order creation.
  const mapHierarchy = (
    part: string,
    description: string,
    objectId: string,
    levelNumber: number,
    partType?: number,
    quantityUsed?: number
  ) => {
    const label = `${part}${description && ` (${description})`}`;
    const link = `/Equipment/${encodeParam(part)}/hierarchy`;
    const detailsLink = `/Equipment/${encodeParam(part)}`;
    const detailsButton = (
      <IonButton routerLink={detailsLink} fill="clear">
        {`${translations["lbl_btn_details"] || "Details"}`}
      </IonButton>
    );
    const children = eqpList.filter((eqp) => {
      return (
        (eqp?.Parent || "").trim() === part &&
        (eqp?.ParentDescription || "").trim() === description &&
        eqp?.LevelNumber === levelNumber
      );
    });
    if (children.length > 0) {
      const item = (
        <>
          <IonItem slot="header" lines="none">
            {partType !== 1 && (
              partType === 5 ? (
                <IonLabel>
                  <h6>{label}{` (${quantityUsed})`}</h6>
                </IonLabel>
              ) : (
                // The original code runs when partType is not 1 and not 5
                <IonLabel>
                  <h6>{label}</h6>
                </IonLabel>
              )
            )}
            {partType === 1 && (
              <>
                <IonButton
                  routerLink={part !== displayEquipment ? link : undefined}
                  fill="clear"
                  style={{ maxWidth: "70%" }}
                >
                  <IonLabel class="hierarchy-label">{label}</IonLabel>
                </IonButton>
                {" - "}
                {detailsButton}
              </>
            )}
          </IonItem>
          {/* Add some paddingLeft to create a tree image */}
          <div slot="content" style={{ paddingLeft: 8 }}>
            {children.map((child) => {
              const description = child.PartDescription || "";
              const childPart = child.Part || "";
              const objectId = child.ObjectId || "";
              return mapHierarchy(
                childPart.trim(),
                description.trim(),
                objectId,
                levelNumber + 1,
                child.PartType,
                child.QuantityUsed
              );
            })}
          </div>
        </>
      );
      if (part === displayEquipment) {
        return (
          <IonAccordionGroup value="first" key={objectId} multiple>
            <IonAccordion value="first">{item}</IonAccordion>
          </IonAccordionGroup>
        );
      }
      return (
        <IonAccordionGroup key={objectId} multiple>
          <IonAccordion>{item}</IonAccordion>
        </IonAccordionGroup>
      );
    }
    //If this is the last part on the hierarchy
    return (
      <IonItem key={objectId} lines="none">
        {partType === 1 && (
          <>
            <IonButton
              fill="clear"
              routerLink={part !== displayEquipment ? link : undefined}
              style={{ maxWidth: "70%" }}
            >
              <IonLabel class="hierarchy-label">{label}</IonLabel>
            </IonButton>
            {" - "}
            {detailsButton}
          </>
        )}
        {partType !== 1 && (
          partType === 5 ? (
            <IonLabel>
              <h6>{label}{` (${quantityUsed})`}</h6>
            </IonLabel>
          ) : (
            // The original code runs when partType is not 1 and not 5
            <IonLabel>
              <h6>{label}</h6>
            </IonLabel>
          )
        )}
      </IonItem>
    );
  };

  return (
    <IonPage>
      <IonLoading isOpen={showLoading} />
      <PageHeader title={displayEquipment} showBack />
      <IonContent class="ion-padding">
        <h3>{`${translations["lbl_hierarchy"] || "Hierarchy"}`}</h3>
        {equipment && hierarchy && elements}
      </IonContent>
    </IonPage>
  );
};

export default EquipmentHierarchy;
