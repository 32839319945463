import { FieldsBase, FieldsMetadata } from "../common/FieldsBase";
import moment from 'moment';
export interface PlannedListItem {
  Bin: string;
  BinQuantity: number;
  BinType: string;
  DefaultIssueQuantity: string;
  DeliveryId: number;
  IsRepairable: boolean;
  IsSerialized: boolean;
  Item: string;
  ItemDescription: string;
  Requisition: string;
  RequisitionLine: number;
  StoresLocation: string;
  LocationQuantityOnHand: number;
  LocationQuantityAvailable: number;
  LocationUnitCost: number;
  LineQuantityRequested: number;
  LineQuantityReserved: number;
  LineQuantityIssued: number;
  LineQuantityReturned: number;
  UnitOfMeasure: string;
  BinList: PlannedListItemBin[];
  SerialIds: any;
}

interface PlannedListItemBin {
  Bin: string;
  BinType: string;
  QuantityOnHand: number;
}

export interface BinData {
  SourceTableName: string;
  RowVersion: any;
  BinType: string;
  QtyOnHand: number;
  LastDateCounted: string;
  DateSelectedForCount: string;
  ObjectId: string;
  Plant: string;
  Item: string;
  StoresLocation: string;
  Bin: string;
  TransactionQuantity?: number;
}

export interface ItemLocationsGetDef {
  SourceTableName: string;
  RowVersion: any;
  Description: string;
  ItemClass: any;
  StoresAccount: any;
  UnitOfIssue: string;
  InspectionLevel: string;
  MechanicalIntegrity: string;
  UnitCost: number;
  Hazard: string;
  NoSubstitutesFlag: boolean;
  RepairableFlag: boolean;
  Specifications: string;
  QtyMaximum: number;
  QtyMinimum: number;
  QtyEoq: number;
  UsageLevel: any;
  Approver: any;
  OrderMethod: string;
  Buyer: any;
  Sic: any;
  QtyOnHand: number;
  QtyPurchUnapproved: number;
  QtyPurchApproved: number;
  QtyOnPurchaseOrder: number;
  QtyPlanned: number;
  QtyRequired: number;
  QtyReserved: number;
  LastDateReceived: string;
  LastDateIssued: string;
  LastDateRestocked: string;
  LastDateOrdered: string;
  DateEntered: string;
  ItemType: string;
  TaxableFlag: boolean;
  Manufacturer: string;
  ManufacturerPartNo: string;
  OverReceiptTolerancePct: number;
  InvoiceTolerancePct: number;
  QtyTransferDueIn: number;
  QtyTransferDueOut: number;
  QtyIssuedOutInTransit: number;
  QtyInReceiving: number;
  UseQtyInReceivingFlag: boolean;
  SerialTrackingFlag: boolean;
  RepairRestockValue: any;
  RepairRestockPercent: number;
  QtyAwaitingRepair: number;
  QtyBeingRepaired: number;
  QtyNotReturnedRepair: number;
  LocalCorpPurchSwitch: any;
  Bin: BinData[];
  Usage: any;
  BinLabelPrint: any;
  BinCount: any;
  BinCountHistory: any;
  ObjectId: string;
  Plant: string;
  Item: string;
  StoresLocation: string;
}

export interface MaterialIssueModal {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (countEntry: CountEntryCard[]) => void;
  batchId: number;
  isCommentsRequired?: boolean;
}

export interface ItemSearchProps {
  storeLocation: string;
  setStoreLocation: (store: string) => void;
  multiWarehouseCheck: boolean;
  isCommentsRequired?: boolean;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (binInfo: BinInfo[]) => void;
  onSelect?: (index: string) => void;
  item?: PlannedListItem;
}

export interface MaterialIssueListItem {
  Bins: { Bin: string; MaterialIssueId: string; TransactionQuantity: number }[];
  Comments: string;
  Item: string;
  ItemIndex: number;
  Requisition: string;
  RequisitionLine: number;
  SerialIds: any;
  StoresLocation: string;
}

export interface MaterialsAccount {
  Account?: string;
  Area?: string;
  Department?: string;
  Project?: string;
}

export interface SearchResult {
  item: string;
  description: string;
}

export interface IssueResponseData {
  MaterialTransactionId: string;
  ReqLine: number;
  MessageList: {
    MessageType: number;
    Text: string;
    Id: any;
    ValidResponses: any;
  }[];
  Success: boolean;
}

export interface BinInfo {
  Bin: string;
  BinType: string;
  QtyOnHand: number;
  Comment: string;
  Item: string;
  StoresLocation: string;
  TransactionQuantity: number;
}

export interface BinDataForAdjustments {
  Bin: string;
  StoresLocation: string;
  Comment: string;
  TransactionQuantity: number;
}

export interface CountEntryCard {
  AssignedTo: any;
  BatchId: number;
  Bin: string;
  Comments: string | null;
  CountId: number;
  CountResult: number;
  CountedBy: any;
  CountedQty: string;
  DatetimeCounted: string;
  Description: string;
  GroupNumber: number;
  Item: string;
  NextCountAssignedTo: any;
  NextCountDate: string;
  ObjectId: string;
  Plant: string;
  QtyOnHand: number;
  RowVersion: any;
  SerialTrackingFlag: boolean;
  SourceTableName: string;
  StoresLocation: string;
  SuperseededFlag: boolean;
  SystemQty: number;
  Variance: number;
  expanded: boolean;
  commentModal: boolean;
  Status: string;
  BlindCountedFlag: boolean;
  ReqCommentOnDiscrepFlag: boolean;
  UnitOfIssue: string;
  ManufacturerPartNo: string;
  ErrorMessage: string | null;
}

export interface CycleCountResults {
  accuracy_222: number;
  batch_id_0: number;
  completeness_222: number;
  completion_date_0: string;
  created_datetime_0: string;
  expected_completion_date_0: string;
  name_0: string;
  plant_0: string;
  status_0: string;
  stores_location_0: string;
  total_items_222: number;
  uncounted_222: number;
}

export interface CycleCountFilterResults {
  filterVisible: boolean;
  onCancel: () => void;
  onConfirm: (multipleSelect: number[]) => void;
  countEntry: CountEntryCard[];
  multipleSelect: number[];
  setMultipleSelect: (multipleSelect: number[]) => void;
}

export interface InventoryField extends FieldsBase {}

const getFieldsFromMetadata = (metadata: FieldsMetadata) => {
  const fields: InventoryField[] = [];
  const metadataFields = metadata.fields;
  const keys = Object.keys(metadataFields);
  keys.forEach((key) => {
    const field = metadataFields[key];
    fields.push(field);
  });
  return fields;
};

export const getAllFields = (metadata: FieldsMetadata) => {
  const fields = getFieldsFromMetadata(metadata);
  return [...fields].sort((a, b) => {
    return +a.FieldOrder - +b.FieldOrder;
  });
};

export const formatData = (data: any, field: InventoryField) => {
  if (!data) {
    return "";
  }
  if (field.ValidValues) {
    const validValue = field.ValidValues.find((value) => {
      return value.Code === String(data);
    });
    return !!validValue ? `${data} - ${validValue?.Description}` : data;
  }
  if(field.PropertyName === "DatetimeCounted") {
    return moment.utc(data).local().format('MM/DD/YY hh:mm:ss A');
  }
  if (field.NetType === "DateTime") {
    const date = new Date(data);
    return date.toLocaleDateString();
  }
  return data;
};
