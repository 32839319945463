import {
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
  } from "@ionic/react";
  import { encodeParam } from "../util/ApiHelper";
  
  interface ISSPopoverParams {
    popover: React.RefObject<HTMLIonPopoverElement>;
    popoverOpen: boolean;
    setPopoverOpen: (popoverOpen: boolean) => void;
    searchResults: string[];
    setSearchResults: (searchResults: string[]) => void;
    searchValue: string;
    setSearchValue: (setSearchValue: string) => void;
    setElementValue: (value: string) => void;
  }
  
  const ISSPopover: React.FC<ISSPopoverParams> = (props) => {
    const {
      popover,
      popoverOpen,
      setPopoverOpen,
      searchResults,
      setSearchResults,
      searchValue,
      setSearchValue,
      setElementValue
    } = props;
  
    return (
      <IonPopover
        keyboardClose={false}
        isOpen={popoverOpen}
        dismissOnSelect
        onDidDismiss={() => {
          setPopoverOpen(false);
          setSearchResults([]);
        }}
        alignment="center"
        ref={popover}
        arrow
      >
        <IonContent>
          <IonList>
            {searchResults.map((result: string) => {
              const formatResult = () => {
                let position = result
                  .toLowerCase()
                  .indexOf(searchValue.toLowerCase());
                return (
                  <span>
                    {result.slice(0, position)}
                    <b>{result.slice(position, position + searchValue.length)}</b>
                    {result.slice(position + searchValue.length, result.length)}
                  </span>
                );
              };
            return (
                <IonItem
                    onClick={() => {
                        setPopoverOpen(false);
                        setSearchResults([]);
                        setSearchValue("");
                        setElementValue(result);
                    }}
                >
                    <IonLabel>{formatResult()}</IonLabel>
                </IonItem>
            );
            })}
          </IonList>
        </IonContent>
      </IonPopover>
    );
  };
  
  export default ISSPopover;
  