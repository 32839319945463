import React, { useContext, useEffect, useRef, useState } from "react";
import {
  SearchbarChangeEventDetail,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonAlert
} from "@ionic/react";
import { TranslationsContext } from "../util/Translations";
import { findStoreItem } from "../api/ItemLocations";
import { SearchResult } from "../models/materialissue/MaterialIssue";
import { useQueryExec } from "../hooks/useQueryExec";
import QueryFilter from "./QueryFilter";
import InventoryResults from "./InventoryResults";
import SearchFooter from "./SearchFooter";
import { chevronBackOutline } from "ionicons/icons";
import { ColumnsWithGroupData, getColumnsWithGroup } from "../api/Windows";
import { GetColumnsWithGroupResponse } from "../models/windows/GetColumnsWithGroup";
import { IonSearchbarCustomEvent } from "@ionic/core";
import {
  performBarCodeShimProcess,
  findObjectByElementType,
} from "../util/BarCodeHelper";
import { useBarcodeManager } from "../hooks/useBarcodeManager";
import BarcodeScannerButton from "./BarcodeScannerButton";

interface MaterialIssueItemSearchProps {
  isOpen: boolean;
  onSelect: (index: string) => void;
  onCancel: () => void;
  storeLocation?: string;
  issueOrReturn: string;
}

const MaterialIssueItemSearch: React.FC<MaterialIssueItemSearchProps> = (
  props
) => {
  const { onSelect, onCancel, storeLocation, issueOrReturn } = props;
  const { translations } = useContext(TranslationsContext);
  const [metadata, setMetadata] = useState<GetColumnsWithGroupResponse>();
  const { scanText, setScanText, scanClick } = useBarcodeManager();
  const [advSearchOpen, setAdvSearchOpen] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [presentAlert] = useIonAlert();
  const {
    getQueryList,
    queryList,
    getFilterParams,
    filterParams,
    setParameter,
    showLoading,
    setShowLoading,
    queryRows,
    executeSearch,
    errorState,
    totalCount,
    queryCriteria,
    getQueryCriteria,
    getNoOfFieldsToDisplay,
    searchYn,
    setSearchYn,
    queryName,
    criteriaParams,
    confirmSearch,
    execFilter,
    resultsFilter,
    filterRows,
  } = useQueryExec("Item", "Item", "item");
  const [itemVal, setItemVal] = useState<string>("");
  const querySection = "QW--Item";
  const metadataQueryCalled = useRef(false); // Ref to track if metadataQuery has been called

  useEffect(() => {
    getIssueAddPartsColumns();
  }, []);

  const getIssueAddPartsColumns = async () => {
    if (metadataQueryCalled.current) return; // If already called, return early
    metadataQueryCalled.current = true;
    const data: ColumnsWithGroupData = {
      WindowName:
        issueOrReturn === "issue"
          ? "mob_inventory_issue_addparts"
          : "mob_inventory_return_addparts",
      IncludeValidValues: "true",
    };
    const response = await getColumnsWithGroup(data);
    setMetadata(response.data);
  };

  const search = async (searchString: string, storeLocation: string) => {
    const params = {
      searchTerm: searchString,
      store: storeLocation,
    };
    const response = await findStoreItem(params);
    const searchResults = parseSearchResponse(response.data);
    setResults(searchResults);
  };

  const getSearchParamters = async (searchString: string) => {
    if(storeLocation === ""){
      presentAlert("Please select a store location to search items.");
    }
    if (storeLocation && searchString && searchString.length > 2) {
      search(searchString, storeLocation);
    } else {
      setResults([]);
    }
  };

  const parseSearchResponse = (response: any) => {
    const results: SearchResult[] = [];
    const keys = Object.keys(response);
    const values = Object.values(response);
    for (let i = 0; i < keys.length; i++) {
      results.push({ item: keys[i], description: String(values[i] || "") });
    }
    return results;
  };

  const handleCancel = () => {
    setScanText("");
    onCancel();
  };

  const handleItemClick = (item: string) => {
    onSelect(item);
    setScanText("");
    handleClose();
  };

  const handleOpen = () => {
    setAdvSearchOpen(true);
  };
  const handleClose = () => {
    setAdvSearchOpen(false);
  };

  async function onScanTextEnter(
    ev: IonSearchbarCustomEvent<SearchbarChangeEventDetail>
  ): Promise<void> {
    let res = String(ev.target.value);
    setItemVal(res);
    if (itemVal === res) return;
    const barCodeFieldData = findObjectByElementType(metadata?.fields);
    const barcodeResult = await performBarCodeShimProcess(
      barCodeFieldData,
      res
    );
    if (!barcodeResult) return;
    if (barcodeResult.NoMatch) {
      setItemVal(res);
      setScanText(res);
      return;
    }
    let capturingGroups = barcodeResult.CapturingGroupValues;
    if (!(capturingGroups?.Item || barcodeResult.FirstCapturingGroup)) return;
    res = capturingGroups?.Item || barcodeResult.FirstCapturingGroup;
    setItemVal(res);
    setScanText(res);
    handleItemClick(res);
  }

  const ResultsModal = (
    <IonModal isOpen={advSearchOpen} onIonModalWillDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleClose}>
              {translations["lbl_btn_cancel"] || "Cancel"}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {translations["lbl_item_search"] || "Item Search"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <QueryFilter
          setShowLoading={setShowLoading}
          getQueryList={getQueryList}
          queryList={queryList}
          querySection={querySection}
          getFilterParams={getFilterParams}
          filterParams={filterParams}
          setParameter={setParameter}
          queryCriteria={queryCriteria}
          getQueryCriteria={getQueryCriteria}
          searchCallback={confirmSearch}
        />
        <InventoryResults
          searchYn={searchYn}
          setSearchYn={setSearchYn}
          queryName={queryName}
          queryParams={filterParams}
          criteria={criteriaParams}
          queryRows={queryRows}
          filterParams={filterParams}
          setParameter={setParameter}
          executeSearch={executeSearch}
          getNoOfFieldsToDisplay={getNoOfFieldsToDisplay}
          errorState={errorState}
          totalCount={totalCount}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
          filterRows={filterRows}
          filterMethod={execFilter}
          filterString={resultsFilter}
          cardClick={handleItemClick}
        />
      </IonContent>
      <SearchFooter totalRows={totalCount} filterRows={filterRows} />
    </IonModal>
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleCancel}>
              <IonIcon icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>{translations["lbl_add_item"] || "Add Item"}</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={scanText}
            onIonChange={(ev) => {
              setScanText(String(ev.detail.value));
              getSearchParamters(String(ev.detail.value));
              onScanTextEnter(ev);
            }}
            debounce={1500}
            placeholder={
              metadata?.fields["in_item_location.item"]?.TranslatedIdText
            }
          ></IonSearchbar>
          <IonButton color="primary" expand="full" onClick={() => handleOpen()}>
            {translations["lbl_btn_mob_advancedsearch"] || "Advanced Search"}
          </IonButton>
          <BarcodeScannerButton textButton onScan={scanClick} expand="block" />
        </IonToolbar>
      </IonHeader>
      {ResultsModal}
      <IonContent class="ion-padding">
        {results.length > 0 && (
          <IonList lines="full">
            {results.map((result, index) => {
              return (
                <IonItem
                  button
                  key={index}
                  onClick={() => {
                    handleItemClick(result.item);
                  }}
                >
                  <IonLabel>{`${result.item} - ${result.description}`}</IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MaterialIssueItemSearch;
