import {FieldsBase} from "../common/FieldsBase";

export interface LaborsMetadata {
  Groups?: any[];
  fields?: any;
  Actions?: any[];
  IsAutoPO?: boolean;
}

export interface LaborsGroup {
  TextId?: string;
  Width?: number;
  GroupOrder: number;
  Icon?: any;
  GroupName?: string;
  ParentGroup?: string;
  Groups?: LaborsGroup[];
  IsRepeatable?: boolean;
  Inflatable?: boolean;
}

export interface LaborsField extends FieldsBase {}

export interface LaborResults {
  SourceTableName?: string,
  RowVersion?: any,
  ContractorFlag?: boolean,
  Department?: any,
  EmpDepartment?: any,
  WorkDate?: string,
  Equipment?: string,
  Area?: string,
  SerialId?: any,
  WorkHours?: number,
  ResourceId?: string,
  PayRate?: number,
  Multiplier?: number,
  RateName?: any,
  WoBase?: string,
  WoTask?: string,
  WoSubtask?: string,
  CertificationDate?: string,
  Occurrence?: number,
  EnteredBy?: string,
  Comments?: string,
  DateExtracted?: string,
  Account?: string,
  ObjectId?: string,
  Plant?: string,
  TransactionDate?: string,
  TransactionTime?: string,
  EmployeeId?: string,
}

export interface Craft {
  Code: string;
  Description: string;
}

export interface Rate {
  Code: string;
  Description: string;
}

export interface Employee {
  EmployeeId: string;
  Properties: any;
}

const getFieldsFromMetadata = (metadata: LaborsMetadata) => {
  const fields: LaborsField[] = [];
  const metadataFields = metadata.fields;
  // Get list of keys from metadata to iterate through
  const keys = Object.keys(metadataFields);
  keys.forEach((key) => {
    const field = metadataFields[key];
    fields.push(field);
  });
  return fields;
};

export const getLaborsGroups = (labor?: LaborsMetadata) => {
  if (!labor) {
    return [];
  }
  const groups = (labor.Groups as LaborsGroup[]) || [];
  return [...groups].sort((a, b) => {
    return a.GroupOrder - b.GroupOrder;
  });
};

export const getFieldsByGroup = (
  metadata: LaborsMetadata,
  groupName?: string
) => {
  if (!groupName) {
    return [];
  }
  const fields = getFieldsFromMetadata(metadata).filter((field) => {
    return field.GroupName === groupName;
  });
  return [...fields].sort((a, b) => {
    return +a.FieldOrder - +b.FieldOrder;
  });
};

export const getAllFields = (metadata: LaborsMetadata) => {
  const fields = getFieldsFromMetadata(metadata);
  return [...fields].sort((a, b) => {
    return +a.FieldOrder - +b.FieldOrder;
  });
};

export const formatData = (data: any, field: LaborsField) => {
  if (!data) {
    return "";
  }
  if (field.ValidValues) {
    const validValue = field.ValidValues.find((value) => {
      return value.Code === String(data);
    });
    return !!validValue ? `${data} - ${validValue?.Description}` : data;
  }
  if (field.NetType === "DateTime") {
    const date = new Date(data);
    return date.toLocaleDateString();
  }
  return data;
};
