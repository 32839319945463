import { HttpMethods, sendRequest } from "../util/ApiHelper"

const CONTROLLER_NAME = "SystemQueries";

export interface CalendarEvent {
    title: string;
    start: string | Date;
    end: string | Date;
};

export const GetScheduleForEmployee = async (plant: string, enddate: string, scheddate: string, userid: string) => {
    return await sendRequest(
        HttpMethods.GET,
        `${CONTROLLER_NAME}/ExecuteQuery/SYS Daily Calendar`,
        undefined,
        {
            plant: plant,
            enddate: enddate,
            scheddate: scheddate,
            userid:userid,
        }
    )
}