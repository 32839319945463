import { useEffect } from "react";
import { ID_TOKEN, signout, signoutPortal } from "../util/OAuth";
import { IonPage, IonLoading, IonHeader, IonToolbar, IonTitle, IonContent, IonLabel } from "@ionic/react";
import { abpLogout, getCustomLogOutUrl } from "../api/abpLogin";
import { getApiSession } from "../util/ApiOptionsHelper";
import { remove } from "../util/Preferences";

const Logout: React.FC = () => {
    useEffect(() => {
        logout();
    }, []);

    async function logout() {
        const apiSession = await getApiSession();
        if (apiSession && apiSession.LicenseSessionId) {
            //abp logout
            await getCustomLogOutUrl();
            await abpLogout();
        }
        //skip portal siggnout for session refresh
        if (!globalThis.relogin) {
            await signoutPortal();
        }
        //b2c logout
        await signout(globalThis.relogin);
        await remove(ID_TOKEN);
        //reset autorelogin flag
        globalThis.relogin = false;
        // TODO: Check if user is working offline or not before clearing cache
        setTimeout(() => {
            window.location.href = "/";
        }, 2000);
    }

    return (
        <IonPage>
            <IonLoading
                cssClass="my-custom-class"
                isOpen={true}
                backdropDismiss={false}
                duration={5000}
            />
            <IonHeader>
                <IonToolbar>
                    <IonTitle>EAM Mobile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonLabel>{globalThis.relogin ? "Refreshing user session..." : "User is being logged off..."}</IonLabel>
            </IonContent>
        </IonPage>
    );
};

export default Logout;