import { IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../util/AppInsights";
import SingleQueryPage from "../components/SingleQueryPage";
import { useContext, useEffect, useState } from "react";
import { add, refresh } from "ionicons/icons";
import {
  getApprovalAccess,
  getStoresAccess,
  getUserRequesterAccess,
  getUserTechnicianAccess,
  getMobReqApprovalAccess
} from "../util/UserHelper";
import { TranslationsContext } from "../util/Translations";
import { AuthContext } from "../util/OAuth";
import { useHistory } from "react-router-dom";
import React from "react";
import StartStopComponent from "../components/StartStopComponent"; // Import the component

const Home: React.FC = () => {
  const { translations } = useContext(TranslationsContext);
  const [title, setTitle] = useState("");
  const [queryName, setQueryName] = useState("");
  const [fabUrl, setFabUrl] = useState("");
  const { loggedIn } = useContext(AuthContext);
  const history = useHistory();
  const [refreshIsTrue, setRefreshIsTrue] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [showStartStopComponent, setShowStartStopComponent] = useState(false);

  const setOptionsByAccess = async () => {
    if (await getUserTechnicianAccess()) {
      setTitle(
        translations["lbl_my_assigned_wos"] || "My Assigned Work Orders"
      );
      setQueryName("My Assigned Work Orders");
      setFabUrl("/quick-work-order/equipment");
      setRefreshIsTrue(true);
    } else if (await getUserRequesterAccess()) {
      setTitle(translations["lbl_my_recent_wos"] || "My Recent WOs");
      setQueryName("My Recent WOs");
      setFabUrl("/requester/create-request/equipment");
    } else if (await getStoresAccess()) {
      history.push("/Stores");
    } else if (await getApprovalAccess()) {
      history.push("/WOApproval");
    }
    else if (await getMobReqApprovalAccess()) {
      history.push("/RequisitionApproval");
    }
    //to do: renders empty page if no access - add message
  };

  const refreshSingleQueryPage = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    setOptionsByAccess();
  }, [queryName, translations, loggedIn]);

  useEffect(() => {
    const storedTimer = localStorage.getItem("StartTime");
    if (storedTimer) {
      setShowStartStopComponent(true);
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
          <>
            <IonMenuButton />
          </>
        </IonButtons>
          <IonTitle>{title}</IonTitle>
          {refreshIsTrue && (
            <IonButton slot="end" onClick={refreshSingleQueryPage}>
              <IonIcon icon={refresh} />
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {showStartStopComponent && <StartStopComponent timeAccess={true} />}
      {!!queryName ? (
        <>
          <SingleQueryPage key={refreshKey} queryName={queryName} />
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton routerLink={fabUrl}>
              <IonIcon icon={add}></IonIcon>
            </IonFabButton>
          </IonFab>
        </>
      ) : (
        <></>
      )}
      </IonContent>

    </IonPage>
  );
};

export default withAITracking(reactPlugin, Home, "Home");
