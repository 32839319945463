import React, { useContext, useEffect, useRef, useState } from "react";
import {
  GetColumnsWithGroupResponse,
  GetColumnsWithGroupField,
} from "../models/windows/GetColumnsWithGroup";
import {
  IonCard,
  IonCardContent,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { Employee } from "../models/employees/Employees";
import { TranslationsContext } from "../util/Translations";
import EmployeeSearch from "./EmployeeSearch";
import CardExpandableHeader from "./CardExpandableHeader";
import { searchOutline, searchSharp } from "ionicons/icons";
import { getValueSearchDetails } from "../api/QueryDesigner";
import { ApiResponse } from "../models/api/ApiResponse";
import { findEmployee } from "../api/Employees";
import ISSPopover from "./ISSPopover";
import FilterModal from "./FilterModal";

interface MaterialIssueDetailsProps {
  columns: GetColumnsWithGroupResponse;
  storeLocation?: string;
  setStoreLocation: (store: string) => void;
  employeeId?: Employee;
  setEmployeeId: (id: Employee) => void;
  employees?: Employee[];
  date?: string;
  setDate: (date: string) => void;
  mode: "issue" | "return";
  displayWo?: string;
  displayReq?: string;
}

const MaterialIssueDetails: React.FC<MaterialIssueDetailsProps> = (props) => {
  const { translations } = useContext(TranslationsContext);
  const {
    columns,
    storeLocation,
    setStoreLocation,
    employeeId,
    setEmployeeId,
    employees,
    date,
    setDate,
    mode,
    displayWo,
    displayReq,
  } = props;
  const [storeLocationField, setStoreLocationField] =
    useState<GetColumnsWithGroupField>();
  const [employeeIdField, setEmployeeIdField] =
    useState<GetColumnsWithGroupField>();
  const [dateField, setDateField] = useState<GetColumnsWithGroupField>();
  const [userSearchOpen, setUserSearchOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const issueDetailText =
    translations["lbl_mob_issuedetails"] || "Issue Details";
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterModalColumnName, setFilterModalColumnName] = useState("");
  const [filterModalModuleName, setFilterModalModuleName] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  let [currentEmployee, setCurrentEmployee] = useState<string>("");

  useEffect(() => {
    if (columns) {
      const storeLocationField: GetColumnsWithGroupField =
        columns?.fields?.["in_log_material.stores_location"];
      storeLocationField && setStoreLocationField(storeLocationField);
      // Default to only store location
      if (
        storeLocationField?.ValidValues &&
        storeLocationField.ValidValues.length === 1
      ) {
        const store = storeLocationField.ValidValues[0];
        setStoreLocation(store.Code);
      }
      const employeeIdField = columns?.fields?.["in_log_material.employee_id"];
      employeeIdField && setEmployeeIdField(employeeIdField);

      const dateField = columns?.fields?.["in_log_material.date_charged"];
      dateField && setDateField(dateField);
    }
  }, [columns]);

  const onStoreLocationSelect = (value?: string) => {
    value && setStoreLocation(value);
  };
  const onEmployeeIdSelect = (value?: Employee) => {
    value && setEmployeeId(value);
    setCurrentEmployee(value?.Properties?.EmployeeId || ""); // Add a default value if value.Properties is undefined
    setUserSearchOpen(false);
  };
  const onDateSelect = (value?: string) => {
    value && setDate(value);
  };

  const handleEmployeeSearch = (e: CustomEvent) => {
    let newVal = (e.target as HTMLInputElement)?.value;
    let empVal = newVal as unknown as Employee
    setEmployeeId(empVal);
    setCurrentEmployee(newVal);
    if (newVal.length > 2) {
      findEmployee(newVal).then((response) => {
        if (response.status === 200) {
          if (Object.keys(response.data).length !== 0) {
            popover.current!.event = e;
            setPopoverOpen(true);
          } else if (popoverOpen) {
            setPopoverOpen(false);
          }
          let empSearchArray = [];
          const employees = Object.entries(response.data).map(([id, name]) => ({
            EmployeeId: id,
            EmployeeName: name
          }));
          for (let search of employees) {
            empSearchArray.push(search.EmployeeId);
          }
          setSearchResults(empSearchArray);
        }
      });
    } else {
      if (popoverOpen) {
        setPopoverOpen(false);
      }
      if (searchResults.length > 0) {
        setSearchResults([]);
      }
    }
  };

  const resetSearchState = () => {
    setFilterModalColumnName('');
    setFilterModalModuleName('');
  };

  const onFilterModalConfirm = (selectedItem: string | number | null | undefined | Employee) => {
    setParameter(filterModalColumnName, selectedItem);
    setIsFilterModalOpen(false);
    resetSearchState();
  };

  const setParameter = (
    paramKey: string,
    paramValue: string | number | null | undefined | Employee
  ) => {
    if (paramKey === 'employee_id') {
      let employeeData = employees?.find((emp) => emp.EmployeeId === paramValue);
      employeeData && setEmployeeId(employeeData);
      setCurrentEmployee(paramValue as string);
    }
  };

  useEffect(() => {
    if (currentEmployee) {
      setParameter('employee_id', currentEmployee);
    }
  }, [currentEmployee]);

  return (
    <>
      <FilterModal
        isOpen={isFilterModalOpen}
        moduleName={filterModalModuleName}
        cancel={() => setIsFilterModalOpen(false)}
        confirm={onFilterModalConfirm}
      />
      <IonCard class="ion-no-margin">
        <CardExpandableHeader
          expanded={expanded}
          onExpandClick={setExpanded}
          title={
            mode === "issue"
              ? !!displayWo
                ? issueDetailText + " for Work Order"
                : !!displayReq
                  ? issueDetailText + " for Requisition"
                  : issueDetailText + " for Account"
              : translations["lbl_mob_returndetails"] || "Return Details"
          }
        />
        <IonCardContent
          style={{ padding: 16, paddingTop: 0 }}
          hidden={!expanded}
        >
          {employeeIdField?.ElementType === "Dropdown" ? (
            <IonItem
              lines="full"
              button
              onClick={() => {
                setUserSearchOpen(true);
              }}
            >
              <IonLabel>{employeeIdField?.TranslatedIdText + "*"}</IonLabel>
              <div slot="end">{employeeId?.EmployeeId}</div>
            </IonItem>
          ) : employeeIdField?.ElementType === "ISS" ? (
            <IonItem>
              <IonInput
                class="ion-text-end"
                slot="end"
                value={currentEmployee || employeeId?.EmployeeId}
                onIonInput={(ev) => {
                  onFilterModalConfirm(ev.target.value);
                  handleEmployeeSearch(ev);
                }}
              ></IonInput>
              <IonIcon
                slot="end"
                ios={searchOutline}
                md={searchSharp}
                onClick={async () => {
                  resetSearchState();
                  setFilterModalColumnName('employee_id');
                  const response: ApiResponse = await getValueSearchDetails(
                    {
                      tableName: 'em_employee',
                      columnName: 'employee_id',
                    }
                  );
                  if (response.status === 200) {
                    setFilterModalModuleName(response.data.QueryWizardModuleName);
                    setIsFilterModalOpen(true);
                  }
                }}
              ></IonIcon>
              <IonLabel slot="start">{employeeIdField?.TranslatedIdText + "*"}</IonLabel>
            </IonItem>
          ) : null}

          <IonItem lines="full">
            <IonLabel>{dateField?.TranslatedIdText + "*"}</IonLabel>
            <IonModal
              keepContentsMounted
              key={dateField?.ColumnName + "-modal"}
            >
              <IonDatetime
                id={`criteria-date-${dateField?.ColumnName}`}
                presentation="date"
                value={date}
                onIonChange={(e) => {
                  onDateSelect(String(e.detail.value));
                }}
              />
            </IonModal>
            <IonDatetimeButton
              datetime={`criteria-date-${dateField?.ColumnName}`}
              key={dateField?.ColumnName + "-button"}
            ></IonDatetimeButton>
          </IonItem>

          <IonItem lines="full">
            <IonLabel>{storeLocationField?.TranslatedIdText + "*"}</IonLabel>
            <IonSelect
              value={storeLocation}
              onIonChange={(ev) => {
                onStoreLocationSelect(ev.detail.value);
              }}
              interface="popover"
            >
              {storeLocationField?.ValidValues?.map((value) => {
                return (
                  <IonSelectOption
                    value={value.Code}
                    key={`store-location-${value.Code}-${value.Description}`}
                  >
                    {value.Code}
                    {value.Description && ` - ${value.Description}`}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
        </IonCardContent>
      </IonCard>
      <EmployeeSearch
        isOpen={userSearchOpen}
        employees={employees || []}
        onSelect={onEmployeeIdSelect}
        onCancel={() => {
          setUserSearchOpen(false);
        }}
        title={employeeIdField?.TranslatedIdText}
      />
      <ISSPopover
        popover={popover}
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setElementValue={setCurrentEmployee}
      />
    </>
  );
};

export default MaterialIssueDetails;
