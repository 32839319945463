import React, { useContext, useEffect, useState } from "react";
import {
  IonContent,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import PageHeader from "../components/PageHeader";
import { useQueryExec } from "../hooks/useQueryExec";
import { TranslationsContext } from "../util/Translations";
import { getColumnsWithGroup } from "../api/Windows";
import { WorkOrderMetadata } from "../models/workorders/WorkOrder";
import ApprovalResults from "../components/ApprovalResults";

const RequisitionApproval: React.FC = () => {
  const queryName = "Mobile Approvals";
  const {
    showLoading,
    setShowLoading,
    queryRows,
    executeSearch,
    errorState,
    totalCount,
    getNoOfFieldsToDisplay,
    execFilter,
    resultsFilter,
    filterRows,
  } = useQueryExec("Approve/Review", "Requisition", "requisition");
  const { translations } = useContext(TranslationsContext);
  const [metadata, setMetadata] = useState<WorkOrderMetadata>();
  const [inRefresh, setInRefresh] = useState(false);
  const approvalName = "Req";

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setInRefresh(true);
    event.detail.complete();
  }

  const approvalMetadata = async () => {
    const data = {
      WindowName: "mob_req_approvals",
      IncludeValidValues: true,
    };
    return getColumnsWithGroup(data).then((response) => {
      setMetadata(response.data);
    });
  };

  const displayApprovalResults = async () => {
    setShowLoading(true);
    approvalMetadata();
    getNoOfFieldsToDisplay("EqNoOfFieldsToDisplay");
    executeSearch(queryName).finally(() => {
      setInRefresh(false);
      setShowLoading(false);
    });
  };

  useEffect(() => {
    displayApprovalResults();
  }, [queryName, inRefresh]);

  return (
    <IonPage>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        duration={5000}
      />
      <PageHeader
        title={translations["lbl_mob_req_approval"] || "Requisition Approval"}
      ></PageHeader>
      <IonContent>
        <IonToolbar>
          <IonTitle>{translations["lbl_reqs"] || "Requisitions"}</IonTitle>
        </IonToolbar>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {!!metadata && (
          <ApprovalResults
            queryRows={queryRows}
            errorState={errorState}
            totalCount={totalCount}
            showLoading={showLoading}
            setShowLoading={setShowLoading}
            filterMethod={execFilter}
            filterString={resultsFilter}
            filterRows={filterRows}
            metadata={metadata}
            displayApprovalResults={displayApprovalResults}
            approvalName={approvalName}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default RequisitionApproval;
